export default [
  {
    name: 'setting-reports',
    path: '/settings/reports',
    component: () => import(/* webpackChunkName: "event" */ './Index'),
    meta: {
      showNavbar: true,
      title: 'Configuração Relatórios',
      icon: 'dvr',
      label: 'Configuração Relatórios',
      roles: ['Admin']
    }
  }
]
