import http from '@/http'
const URL = '/admin/settings/reports'

export const listColumns = async (params) => {
  const list = (await http.get(URL + '/columns', { params: params }))
  return list
}

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}`))
  }
  return one
}

export const save = (payload) => {
  if (payload.id) {
    return http.put(URL + `/${payload.id}`, payload)
  }
  return http.post(URL, payload)
}
export const deleteC = async (id) => http.delete(URL + `/${id}`)
export const orderList = async (payload) => http.put(URL + `/sequence`, payload)

export default {
  list,
  listColumns,
  show,
  save,
  deleteC,
  orderList
}
