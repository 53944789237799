import services from '../services'
import * as types from './mutation-types'

export const getAll = ({ commit }, params) => (
  services.list(params).then(res => {
    commit(types.SET_REPORTS, res.data)
  })
)

export const show = async ({ commit }, id) => {
  await services.show(id).then(res => {
    commit(types.SET_REPORT, res.data.data)
  })
}

export const save = async ({ commit }, obj) => {
  await services.save(obj).then(res => {
    commit(types.SET_REPORT, res.data.data)
    return res
  })
}

export const orderList = async ({ commit }, obj) => {
  await services.orderList(obj).then(res => {
    commit(types.SET_REPORTS, res.data.data)
    return res.data
  })
}

export const deleteR = async ({ commit }, obj) => {
  await services.deleteC(obj).then(res => {
    return res
  })
}
